import authRequest from '../authRequest';

const fetchSharedQuery = async (share_token) => {
  try {
    const response = await authRequest(`/q/share/${share_token}`, { method: 'GET' });
    return response.data;
  } catch (error) {
    console.error('Fetching shared query failed:', error);
    throw error;
  }
};

export default fetchSharedQuery;
