import React from 'react';
import { Button } from 'antd';
import { ThunderboltOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

function FinalizeButton({ handleFinalize, hasStarted, hasPaused, isFinalizing }) {
  const { t } = useTranslation();
  const buttonText = t('session_recorder.fields.finalize');
  const icon = <ThunderboltOutlined className='iconSpacing' />;

  return (
    <Button
      type="default"
      shape="round"
      size="large"
      onClick={handleFinalize}
      disabled={isFinalizing || !hasStarted}
      data-testid="finalize-button"
      style={{ visibility: hasPaused ? 'inherit' : 'hidden', minWidth: '250px' }}
    >
      {icon}{buttonText}
    </Button>
  );
}

export default FinalizeButton;
