import React from 'react';
import { Card, Typography, Button, Row, Col, Grid } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const HomeFeature = ({ feature }) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();

  return (
    <Card
      style={{
        borderTop: `4px solid ${feature.iconColor}`,
        borderRadius: '8px',
        margin: '0 auto',
        minHeight: '300px',
      }}
      bodyStyle={{
        padding: screens.xs ? '12px' : '16px', // Reduced padding on smaller screens
        height: '100%',
      }}
    >
      {screens.xs ? (
        // On small screens, rearranged layout to reduce whitespace and hide icons
        <Row gutter={[8, 8]} align="middle">
          {/* Icons are hidden on mobile */}
          <Col flex="auto">
            <Row justify="space-between" align="middle">
              <Col>
                <Title
                  level={4}
                  style={{
                    fontWeight: '400',
                    margin: 0,
                    fontSize: '16px',
                  }}
                >
                  {t(`home.products.${feature.key}.title`)}
                </Title>
              </Col>
              <Col>
                <Link to={feature.link}>
                  <Button style={{ fontWeight: '300' }}>
                    {t(`home.products.${feature.key}.buttonText`)}
                  </Button>
                </Link>
              </Col>
            </Row>
            <Paragraph
              style={{
                fontWeight: '300',
                marginTop: '8px',
                marginBottom: 0,
                fontSize: '14px',
              }}
            >
              {t(`home.products.${feature.key}.description`)}
            </Paragraph>
          </Col>
        </Row>
      ) : (
        // On larger screens, keep original layout with icons
        <Row gutter={[16, 16]} align="middle">
          <Col flex="64px">
            <div
              style={{
                width: '64px',
                height: '64px',
                borderRadius: '50%',
                backgroundColor: feature.iconBackgroundColor,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {React.cloneElement(feature.icon, {
                style: { fontSize: '32px', color: feature.iconColor },
              })}
            </div>
          </Col>
          <Col flex="auto">
            <Title
              level={4}
              style={{
                fontWeight: '400',
                margin: 0,
                fontSize: '18px',
              }}
            >
              {t(`home.products.${feature.key}.title`)}
            </Title>
            <Paragraph
              style={{
                fontWeight: '300',
                marginTop: '12px',
                marginBottom: '16px',
                fontSize: '16px',
              }}
            >
              {t(`home.products.${feature.key}.description`)}
            </Paragraph>
          </Col>
          <Col xs={24} sm="auto" style={{ textAlign: 'right' }}>
            <Link to={feature.link}>
              <Button style={{ fontWeight: '300' }}>
                {t(`home.products.${feature.key}.buttonText`)}
              </Button>
            </Link>
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default HomeFeature;
