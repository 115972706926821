import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Space, Skeleton, Descriptions, Typography, Layout, Row, Col } from 'antd';
import BackLink from '../components/BackLink';
import SessionDetailsLoadingFailedInfo from '../components/session/SessionDetailsLoadingFailedInfo';
import SessionDetailsNonMedicalInfo from '../components/session/SessionDetailsNonMedicalInfo';
import SessionDetailsMedicalBasicInfo from '../components/session/SessionDetailsMedicalBasicInfo';
import fetchSession from '../utils/session/fetchSession';
import { handleError } from '../utils/errorHandling';
import SessionPresenter from '../presenters/SessionPresenter';
import SessionDetailsTabs from '../components/session/SessionDetailsTabs';
import SessionLoadingCard from "../components/session/SessionLoadingCard";

const { Title } = Typography;
const { Content } = Layout;

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const pollSession = async (uuid) => {
  const startTime = Date.now();
  const timeout = 300000; // 5 minutes in milliseconds

  while (true) {
    if (Date.now() - startTime > timeout) {
      throw new Error('Polling timeout reached');
    }
    console.log(`pollSession: fetching session uuid ${uuid}`);
    const receivedSession = await fetchSession(uuid);

    if (receivedSession.isReady()) {
      console.log(`pollSession: received session has necessary data, stopping polling`);
      return receivedSession;
    }

    await sleep(2000);
  }
};

function SessionDetails() {
  const { t } = useTranslation();
  const { uuid } = useParams();
  const location = useLocation();
  const [session, setSession] = useState(new SessionPresenter(null));
  const [sessionLoading, setSessionLoading] = useState(true);
  const [loadingFailed, setLoadingFailed] = useState(false);
  const query = new URLSearchParams(location.search);
  const generating = query.get('generating') === '1';

  useEffect(() => {
    const fetchSessionDetails = async () => {
      setSessionLoading(true);
      try {
        // Fetch initial session data
        const initialSession = await fetchSession(uuid);
        setSession(initialSession);
        setSessionLoading(false);

        // If generating, start polling
        if (generating) {
          const updatedSession = await pollSession(uuid);
          setSession(updatedSession);
        }
      } catch (error) {
        handleError(error);
        setLoadingFailed(true)
      }
    };

    fetchSessionDetails();
  }, [uuid]);

  if (sessionLoading) {
    return (
      <div className='page'>
        <div className='sessions--skeleton' data-testid='loading-skeleton'>
          <Skeleton />
        </div>
      </div>
    );
  }

  if (!loadingFailed && !session.isReady()) {
    return (
      <Content className='page'>
        <SessionLoadingCard/>
      </Content>
    )
  }

  return (
    <Content className='page'>
      <Space direction='vertical' size={16}>
        <Row align="middle" gutter={[16, 16]} style={{ marginBottom: '16px' }}>
          <Col flex="none">
            <BackLink to='/sessions' label={t('navigation_header.sessions')}/>
          </Col>
          <Col flex="auto">
            <Title level={4} style={{ margin: 0, marginLeft: '10px' }}>
              {session.getTitle()}
            </Title>
          </Col>
        </Row>
        <div style={{ maxWidth: '800px' }}>
          {loadingFailed && !session.isReady() && <SessionDetailsLoadingFailedInfo/>}
          {session.isNonMedical() && <SessionDetailsNonMedicalInfo session={session} generating={generating}/>}
          {session.displayNote() && session.isMedicalBasic() && <SessionDetailsMedicalBasicInfo session={session}/>}
          {session.displayNote() && <SessionDetailsTabs session={session}/>}
          {session.displayNote() &&
            <div style={{ marginTop: '16px'}}>
              <BackLink to='/sessions' label={t('navigation_header.sessions')}/>
            </div>
          }
        </div>
      </Space>
    </Content>
);
}

export default SessionDetails;
