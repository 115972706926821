import authRequest from '../authRequest';

const fetchPublicQuery = async (public_slug) => {
  try {
    const response = await authRequest(`/q/public/${public_slug}`, { method: 'GET' });
    return response.data;
  } catch (error) {
    console.error('Fetching shared query failed:', error);
    throw error;
  }
};

export default fetchPublicQuery;
