import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton, Layout } from 'antd';
import QueryDetailsCard from '../components/query/QueryDetailsCard';
import fetchPublicQuery from '../utils/query/fetchPublicQuery';
import NotFoundPage from "./NotFoundPage";
import usePageMeta from '../hooks/usePageMeta';

const { Content } = Layout;

const PublicQuery = () => {
  const { public_slug } = useParams();
  const [query, setQuery] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadQuery = async () => {
      try {
        const data = await fetchPublicQuery(public_slug);
        setQuery(data);
      } catch (error) {
        setError('Failed to load the shared query.');
      } finally {
        setLoading(false);
      }
    };

    loadQuery();
  }, [public_slug]);

  // Use the usePageMeta hook to dynamically set metadata for SEO
  usePageMeta(query?.question);

  if (loading) return (
    <Content className="page public-page">
      <Skeleton active />
    </Content>
  );
  if (error) return <NotFoundPage />;

  return (
    <Content className="page public-page">
      <QueryDetailsCard query={query} protectedPage={false} />
    </Content>
  );
};

export default PublicQuery;
