import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu, Divider, Grid, Tag, ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import { getCurrentUser } from '../utils/userStorage';
import authRequest from '../utils/authRequest';
import { handleAuthFailure } from '../utils/authFailure';
import ShareLoveButton from './buttons/ShareLoveButton';
import {
  HomeOutlined,
  FileTextOutlined,
  FolderOpenOutlined,
  MessageOutlined,
  UserOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import logo from '../assets/logo_es.png';

const { Sider } = Layout;
const { useBreakpoint } = Grid;

function SideNavigation() {
  const { t } = useTranslation();
  const location = useLocation();
  const currentUser = getCurrentUser();
  const userEmail = currentUser?.email || '';
  const [collapsed, setCollapsed] = useState(false);
  const screens = useBreakpoint();

  useEffect(() => {
    setCollapsed(!screens.md); // Automatically collapse for smaller screens
  }, [screens.md]);

  const handleSignOut = async () => {
    try {
      // Send DELETE /logout request
      await authRequest('/logout', {
        method: 'DELETE',
      });
    } catch (error) {
      console.error('Error logging out:', error);
    } finally {
      // Clear the token and currentUser, and navigate to sign-in page
      await handleAuthFailure();
    }
  };

  const getSelectedKey = () => {
    const path = location.pathname;
    if (path === '/') return '1';
    if (path.startsWith('/queries')) return '2';
    if (path.startsWith('/sessions')) return '3';
    if (path.startsWith('/cases')) return '4';
    return '';
  };

  const menuItems = [
    {
      key: '1',
      icon: <HomeOutlined />,
      label: t('navigation_header.home'),
      link: '/',
    },
    {
      key: '2',
      icon: <MessageOutlined />,
      label: t('navigation_header.queries'),
      link: '/queries/new',
    },
    {
      key: '3',
      icon: <FileTextOutlined />,
      label: t('navigation_header.sessions'),
      link: '/sessions',
    },
    {
      key: '4',
      icon: <FolderOpenOutlined />,
      label: (
        <>
          {t('navigation_header.cases')}
          <Tag color="#108ee9" style={{ marginLeft: '8px', fontSize: '10px', padding: '2px 4px', lineHeight: '1' }}>
            {t('common.new')}
          </Tag>
        </>
      ),
      link: '/cases',
    },
  ];

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            darkItemSelectedBg:'#003f3f',
            darkItemHoverBg: '#001f1f ',
            darkSubMenuItemBg: '' // removing the black background
          }
        }
        }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
        breakpoint="md"
        collapsedWidth={80}
        trigger={null}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div style={{ padding: '16px', textAlign: 'center' }}>
          <Link to="/">
            <img src={logo} alt="Logo" style={{ width: collapsed ? '40px' : '120px', marginBottom: '16px' }} />
          </Link>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[getSelectedKey()]}
          items={menuItems.map((item) => ({
            key: item.key,
            icon: item.icon,
            title: collapsed ? false : item.title,
            tooltip: false,
            label: <Link to={item.link}>{item.label}</Link>
          }))}
        />
        <Divider style={{ margin: '16px 0', borderColor: 'rgba(255, 255, 255, 0.3)' }} />
        <div>
          <Menu
            theme="dark"
            mode="inline"
            selectable={false}
            items={[
              {
                key: '5',
                icon: <ShareLoveButton />,
              },
              {
                key: '6',
                icon: <UserOutlined />,
                label: t('settings.account'), // Label for the Account section
                children: [
                  {
                    key: '6-1',
                    label: userEmail, // Display the user's email as a disabled item
                    disabled: true,
                  },
                  {
                    key: '6-2',
                    icon: <LogoutOutlined />,
                    label: t('sign_out.title'),
                    onClick: handleSignOut, // Use your actual sign-out function
                  },
                ],
              },
            ]}
          />
        </div>
      </Sider>
    </ConfigProvider>
  );
}

export default SideNavigation;
