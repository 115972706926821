import React, { useState, useEffect } from 'react';
import { Skeleton, Space, Layout, Card, Typography, Pagination, Divider } from "antd";
import { useTranslation } from 'react-i18next';
import fetchPublicQueriesList from '../utils/query/fetchPublicQueriesList';
import { Link } from "react-router-dom";

const { Content } = Layout;
const { Title, Paragraph, Text } = Typography;

const PublicQueries = () => {
  const [queries, setQueries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const { t } = useTranslation();

  useEffect(() => {
    const fetchQueries = async () => {
      setLoading(true);
      try {
        const { queries: fetchedQueries, meta } = await fetchPublicQueriesList(pagination.current);
        setQueries(fetchedQueries);
        setPagination(prev => ({
          ...prev,
          total: meta?.total_entries || 0,
          current: meta?.current_page || 1,
        }));
      } catch (error) {
        console.error('Error fetching public queries:', error);
        // Handle error state here if needed
      } finally {
        setLoading(false);
      }
    };

    fetchQueries();
  }, [pagination.current]);

  const handlePaginationChange = (page) => {
    setPagination(prev => ({ ...prev, current: page }));
  };

  if (loading) {
    return (
      <Content className="page public-queries-page public-queries--skeleton">
        <Skeleton active />
        <Space />
        <Skeleton active />
        <Space />
        <Skeleton active />
      </Content>
    );
  }

  return (
    <Content className="page public-queries-page">
      <Card style={{ marginTop: '2rem' }}>
        {queries.map((query, index) => (
          <React.Fragment key={query.uuid}>
            {index > 0 && <Divider style={{ margin: '2rem 0' }} />}
            <article style={{ marginBottom: '2rem' }}>
              <Link to={`/q/${query.slug}`}>
                <Title level={2} style={{ marginBottom: '0.5rem', fontSize: '1.5rem' }}>{query.question}</Title>
              </Link>
              <Text italic type="secondary" style={{ display: 'block', marginBottom: '1rem', fontSize: '0.9rem' }}>
                answered on {new Date(query.published_at).toLocaleDateString()}
              </Text>
              <Paragraph
                ellipsis={{
                  rows: 3,
                }}
                style={{
                  fontSize: '1rem',
                  lineHeight: '1.6',
                  color: 'rgba(0, 0, 0, 0.65)',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                {query.gen_answer}
                <div style={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  height: '50%',
                  background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))',
                  pointerEvents: 'none',
                }} />
              </Paragraph>
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '1rem' }}>
                <Link to={`/q/${query.slug}`}>
                  <Text style={{ color: '#1890ff' }}>{t('public_queries.read_more')}</Text>
                </Link>
              </div>
            </article>
          </React.Fragment>
        ))}
      </Card>
      <div style={{ marginTop: '2rem', textAlign: 'center' }}>
        <Pagination
          current={pagination.current}
          total={pagination.total}
          pageSize={pagination.pageSize}
          onChange={handlePaginationChange}
          showSizeChanger={false}
        />
      </div>
    </Content>
  );
};

export default PublicQueries;
